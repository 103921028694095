<template>
  <div id="lom-events-create">
    <v-tour name="myTour" :steps="TourSteps" :options="TourOptions"></v-tour>
    <v-card elevation="1" color="#F5F5F5">
          <v-dialog v-model="addFacultyRecordPrompt" persistent max-width="75%">
            <add-event-faculty
              pageTitle="Add Guest Details"
              pageDescription="Add event faculty"
              :addFacultyRecordPrompt="addFacultyRecordPrompt"
              @hideAddFacultyRecordPrompt="addNewFaculty($event)"
              v-if="addFacultyRecordPrompt"
            ></add-event-faculty>
          </v-dialog>
          <v-dialog
            v-model="addDocumentRecordPrompt"
            persistent
            max-width="75%"
          >
            <add-event-document
              pageTitle="Add Event Document"
              pageDescription="Add event document"
              :addDocumentRecordPrompt="addDocumentRecordPrompt"
              @hideAddDocumentRecordPrompt="addNewDocument($event)"
              v-if="addDocumentRecordPrompt"
            ></add-event-document>
          </v-dialog>
          <v-dialog
            v-model="addChairmanRecordPrompt"
            persistent
            max-width="75%"
          >
            <add-event-chairman
              v-if="addChairmanRecordPrompt"
              :showDialog="addChairmanRecordPrompt"
              @hideDialog="hideChairmanDialog($event)"
              pageTitle="Add Event Chairman"
              pageDescription="Add event chairman"
            ></add-event-chairman>
          </v-dialog>
          <v-dialog
            v-model="addDirectorRecordPrompt"
            persistent
            max-width="75%"
          >
            <add-event-director
              v-if="addDirectorRecordPrompt"
              :showDialog="addDirectorRecordPrompt"
              @hideDialog="hideDirectorDialog($event)"
              pageTitle="Add Event Director"
              pageDescription="Add event director"
            ></add-event-director>
          </v-dialog>
          <v-dialog eager v-model="FilePreviewFlag" v-if="FilePreviewFlag">
            <pdf-document-file-preview
              PageTitle="File Preview"
              :PreviewButtonFlag="FilePreviewFlag"
              :Documentpath="OldUploadedFile"
              @hideDialog="hideFilePreviewFlag"
              v-if="FilePreviewFlag"
            ></pdf-document-file-preview>
          </v-dialog>
      <v-card-text>
        <v-container>
                    <v-card elevation="3" color="#3a67b1" v-if="MemeberFullname != ''">
            <v-card-text>
                        <v-row wrap>
          <v-col align="center" cols="12" md="12" class="mycard-title-2 fsize-5 fcolor-1">
              <!-- <p><span class="text-danger">*</span> indicates required field</p> -->
              <p> Welcome {{ MemeberFullname }}, </p>
              <p> {{ CurrentDesignation }}, {{ LomName }} </p>
          </v-col>
        </v-row>
            </v-card-text>
            </v-card>
                    <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                @click.prevent="backPage"
                color="#EDBE38"
                elevation="30"
                shaped
                class="font-size-h6 fsize-3 mr-3 my-3 white--text"
              >
                <v-icon dark> mdi-arrow-left-circle </v-icon> &nbsp; Back
              </v-btn>
              <v-btn
                @click.prevent="refreshPageData"
                color="#EDBE38"
                elevation="30"
                shaped
                class="font-size-h6 fsize-3 mr-3 my-3 white--text"
              >
                <v-icon dark> mdi-refresh </v-icon> &nbsp; Refresh
              </v-btn>
              <v-btn
                v-if="PageInfo.TourFlag == 1"
                @click.prevent="startTour"
                color="#EDBE38"
                elevation="30"
                shaped
                class="font-size-h6 fsize-3 mr-3 my-3 white--text"
              >
                <v-icon>mdi-lightbulb-on</v-icon> &nbsp; Need
                help? Take demo
              </v-btn>
            </v-col>
          </v-row>

                  <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>

          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="confirmAlert"
          >
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">
                Date & Host & Venue &nbsp;
                <v-tooltip right max-width="60%" allow-overflow>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon color="#F19A3E"> mdi-help-circle </v-icon>
                    </span>
                  </template>
                  <span class="mycard-text2">
                    Fill in the date and time of the event and the venue
                    details.
                  </span>
                </v-tooltip>
              </v-card-title>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="4" md="4" id="step1">
                    <label>
                      <h6 class="mycard-text1 fsize-3"><span class="text-danger">*</span> Event Day Type</h6>
                    </label>
                    <v-select
                      :reduce="(option) => option.value"
                      :loading="DayTypeOptionsLoading"
                      :items="DayTypeOptions"
                      :rules="DayTypeRules"
                      v-model="DayType"
                      required
                      outlined
                      dense
                    >
                    <template v-slot:prepend>
                      <v-icon color="#2B9EB3"> mdi-calendar </v-icon>
                    </template>
                    </v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    lg="3"
                    md="3"
                    v-if="DayType != ''"
                  >
                    <h6 class="mycard-text1 fsize-3"><span class="text-danger">*</span> From Date</h6>
                    <!-- <v-datetime-picker
                      v-model="FromDate"
                      :text-field-props="textFieldProps1"
                      :date-picker-props="dateProps1"
                      :time-picker-props="timeProps1"
                      date-format="yyyy-MM-dd"
                      time-format="HH:mm"
                    ></v-datetime-picker> -->
                    <v-dialog
                      ref="dialog1"
                      v-model="DateModal1"
                      :return-value.sync="FromDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="FromDate"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        >
                        <template v-slot:prepend>
                      <v-icon color="#2B9EB3"> mdi-calendar </v-icon>
                    </template>
                        </v-text-field>
                      </template>
                      <v-date-picker v-model="FromDate" scrollable :min="FromDateMin" :max="FromDateMax" >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="DateModal1 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog1.save(FromDate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                    <!-- <v-menu
                      v-model="DateModal1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="FromDate"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="FromDate"
                        @input="DateModal1 = false"
                      ></v-date-picker>
                    </v-menu> -->
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    lg="3"
                    md="3"
                    v-if="DayType == 2"
                  >
                    <h6 class="mycard-text1 fsize-3"><span class="text-danger">*</span> To Date</h6>
                    <!-- <v-datetime-picker
                      v-model="ToDate"
                      :text-field-props="textFieldProps2"
                      :date-picker-props="dateProps2"
                      :time-picker-props="timeProps2"
                      date-format="yyyy-MM-dd"
                      time-format="HH:mm"
                    ></v-datetime-picker> -->
                    <v-dialog
                      ref="dialog2"
                      v-model="DateModal2"
                      :return-value.sync="ToDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="ToDate"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        >
                        <template v-slot:prepend>
                      <v-icon color="#2B9EB3"> mdi-calendar </v-icon>
                    </template>
                        </v-text-field>
                      </template>
                      <v-date-picker v-model="ToDate" scrollable :min="ToDateMin" :max="ToDateMax">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="DateModal2 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog2.save(ToDate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" sm="12" lg="12" md="12">
                    <v-row>
                      <v-col cols="12" sm="12" md="12" lg="4">
                        <label>
                          <h6 class="mycard-text1 fsize-3"> <span class="text-danger">* </span>Hosted by JCI India? </h6>
                        </label>
                        <v-switch
                            v-model="HostType"
                            :label="`${HostType == 1 ? 'Yes' : 'No'}`"
                          ></v-switch>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" v-if="!HostType">
                          <label>
                            <h6><span class="text-danger">*</span> Host Zone</h6>
                          </label>
                          <v-autocomplete
                            :reduce="(option) => option.value"
                            :loading="HostZoneCodeOptionsLoading"
                            :items="HostZoneCodeOptions"
                            :rules="HostZoneCodeRules"
                            v-model="HostZoneCode"
                            dense
                            clearable
                            required
                            outlined
                          ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" v-if="!HostType">
                          <label>
                            <h6><span class="text-danger">*</span> Host LOM</h6>
                          </label>
                          <v-autocomplete
                            :reduce="(option) => option.value"
                            v-model="HostLomCode"
                            :rules="HostLomCodeRules"
                            :items="HostLomCodeOptions"
                            :loading="HostLomCodeOptionsLoading"
                            dense
                            clearable
                            required
                            outlined
                          ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="12" lg="12" md="12" id="step3">
                    <label>
                      <h6 class="mycard-text1 fsize-3">Venue Details <small> (Optional)</small></h6>
                    </label>
                    <v-text-field
                      v-model="VenueDetails"
                      :rules="VenueDetailsRules"
                      hint="Enter the venue details"
                      outlined
                      dense
                    >
                    <template v-slot:prepend>
                      <v-icon color="#2B9EB3"> mdi-map-marker-radius </v-icon>
                    </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br /><br />
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">
                <span> Event / Program / Activity &nbsp; </span>
                <v-tooltip right max-width="60%" allow-overflow>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon color="#F19A3E"> mdi-help-circle </v-icon>
                    </span>
                  </template>
                  <span>
                    Choose the portfolio and the suitable category under which
                    this event falls under
                  </span>
                </v-tooltip>
              </v-card-title>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="4" md="4" id="step4">
                    <label>
                      <h6 class="mycard-text1 fsize-3"><span class="text-danger">*</span> Please select Portfolio </h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      v-model="PortfolioId"
                      :rules="PortfolioIdRules"
                      :items="PortfolioIdOptions"
                      :loading="PortfolioIdOptionsLoading"
                      dense
                      clearable
                      required
                      outlined
                    >
                    <template v-slot:prepend>
                      <v-icon color="#2B9EB3"> mdi-file-document </v-icon>
                    </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4" id="step5">
                    <label>
                      <h6 class="mycard-text1 fsize-3"><span class="text-danger">*</span> Event Category</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      v-model="EventCategoryId"
                      :rules="EventCategoryIdRules"
                      :items="EventCategoryIdOptions"
                      :loading="EventCategoryIdOptionsLoading"
                      dense
                      clearable
                      required
                      outlined
                    >
                    <template v-slot:prepend>
                      <v-icon color="#2B9EB3"> mdi-format-list-bulleted </v-icon>
                    </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <label>
                      <h6 class="mycard-text1 fsize-3">Event Sub Category <small> (Optional)</small></h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      v-model="EventSubTypeId"
                      :rules="EventSubTypeIdRules"
                      :items="EventSubTypeIdOptions"
                      :loading="EventSubTypeIdOptionsLoading"
                      dense
                      clearable
                      required
                      outlined
                    >
                    <template v-slot:prepend>
                      <v-icon color="#2B9EB3"> mdi-format-list-bulleted </v-icon>
                    </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" lg="12" md="12" id="step6">
                    <label>
                      <h6 class="mycard-text1 fsize-3"><span class="text-danger">*</span> Event Name</h6>
                    </label>
                    <v-text-field
                      v-model="EventName"
                      :rules="EventNameRules"
                      hint="Enter the event name"
                      outlined
                      dense
                    >
                    <template v-slot:prepend>
                      <v-icon color="#2B9EB3"> mdi-file-document-edit </v-icon>
                    </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br /><br />
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">
                Is it Eligible for 100% Efficiency Criteria &nbsp;
                <v-tooltip right max-width="60%" allow-overflow>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon color="#F19A3E"> mdi-help-circle </v-icon>
                    </span>
                  </template>
                  <span>
                    Choose the 100% efficiency criteria if this event is
                    eligible
                  </span>
                </v-tooltip>
              </v-card-title>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="12" lg="12" md="12" id="step7">
                    <label>
                      <h6 class="mycard-text1 fsize-3">100% Efficiency</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      v-model="EfficiencyId"
                      :rules="EfficiencyIdRules"
                      :items="EfficiencyIdOptions"
                      :loading="EfficiencyIdOptionsLoading"
                      dense
                      clearable
                      required
                      outlined
                    >
                    <template v-slot:prepend>
                      <v-icon color="#2B9EB3"> mdi-bullseye-arrow </v-icon>
                    </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br /><br />
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">
                Event Images &nbsp;
                <v-tooltip right max-width="60%" allow-overflow>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon color="#F19A3E"> mdi-help-circle </v-icon>
                    </span>
                  </template>
                  <span>
                    Upload the event invitation and a photo to be displayed in
                    JCI India website. Note: The photo is expected to be a
                    professional group photo only then it will be approved to be
                    displayed in JCI India website
                  </span>
                </v-tooltip>
              </v-card-title>
              <p class="notes-text1">
                Kindly note:
                <ol>
                  <li>Only JPEG, PNG file types are allowed to be uploaded.</li>
                  <li>Max file size per image should not exceed 1 MB.</li>
                </ol>
              </p>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="4" lg="4" md="4">
                    <h6 class="mycard-text1 fsize-3">Selected Invitation</h6>
                    <v-img
                      :src="InvitationOldImage"
                      height="100"
                      contain
                      v-if="InvitationOldImage != ''"
                    />
                      <v-icon v-if="InvitationOldImage != ''" color="red" @click="deleteOldFile(1)" class="delete-icon"> mdi-delete </v-icon>
                  </v-col>
                  <v-col cols="12" sm="4" lg="4" md="4">
                    <h6 class="mycard-text1 fsize-3">Invitation <small> (Optional)</small></h6>
                    <v-file-input
                      :clearable="false"
                      truncate-length="25"
                      accept="image/png, image/jpeg, image/bmp"
                      placeholder="Upload Invitation"
                      v-model="InvitationImage.UploadedImage"
                      prepend-icon="mdi-card-bulleted-settings"
                      @change="addImage(1)"
                    >
                    <template v-slot:append-outer>
                      <v-icon color="red" @click="deleteImage(1)"> mdi-delete </v-icon>
                    </template>
                    </v-file-input>
                    <!-- <input
                  type="file"
                  name="profile_avatar"
                  accept=".png, .jpg, .jpeg"
                  @change="loadImage($event, 1)"
                />
                <v-btn fab dark small color="blue" @click="deleteImage(1)">
                  <v-icon>mdi-upload</v-icon>
                </v-btn>
                <v-btn fab dark small color="red" @click="deleteImage(1)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn> -->
                  </v-col>
                  <v-col cols="12" sm="4" lg="4" md="4">
                    <h6 class="mycard-text1 fsize-3">New Selected Invitation</h6>
                    <v-img
                      :src="InvitationImage.ImageUrl"
                      height="100"
                      contain
                      v-if="InvitationImage.ImageUrl != ''"
                    />
                  </v-col>
                  <v-col cols="12" sm="4" lg="4" md="4">
                    <h6 class="mycard-text1 fsize-3">Selected Event Photo</h6>
                    <v-img
                      :src="EventPhotoOldImage"
                      height="100"
                      contain
                      v-if="EventPhotoOldImage != ''"
                    />
                    <v-icon v-if="EventPhotoOldImage != ''" color="red" @click="deleteOldFile(2)" class="delete-icon"> mdi-delete </v-icon>
                  </v-col>
                  <v-col cols="12" sm="4" lg="4" md="4">
                    <h6 class="mycard-text1 fsize-3">
                      Event Photo for JCI India Website
                      <small> (Optional)</small>
                    </h6>
                    <v-file-input
                      :clearable="false"
                      truncate-length="25"
                      accept="image/png, image/jpeg, image/bmp"
                      placeholder="Upload Event Photo"
                      v-model="EventPhotoImage.UploadedImage"
                      prepend-icon="mdi-account-group"
                      @change="addImage(2)"
                    >
                    <template v-slot:append-outer>
                      <v-icon color="red" @click="deleteImage(2)"> mdi-delete </v-icon>
                    </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" sm="4" lg="4" md="4">
                    <h6 class="mycard-text1 fsize-3">New Selected Event Photo</h6>
                    <v-img
                      :src="EventPhotoImage.ImageUrl"
                      height="100"
                      contain
                      v-if="EventPhotoImage.ImageUrl != ''"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br /><br />
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">
                Event Chairman Details &nbsp;
                <v-tooltip right max-width="60%" allow-overflow>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon color="#F19A3E"> mdi-help-circle </v-icon>
                    </span>
                  </template>
                  <span>
                    Choose the event chairman by clicking the <v-icon color="blue">mdi-plus-circle</v-icon> icon below
                  </span>
                </v-tooltip>
              </v-card-title>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="12" lg="12" md="12">
                    <v-btn
                      icon
                      color="blue"
                      @click.prevent="addChairmanRecordPrompt = true"
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                      <v-icon>mdi-edit-circle</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      color="red"
                      @click="deleteRow(3, 1, ChairmanData)"
                    >
                      <v-icon>mdi-delete-circle-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6 class="mycard-text1 fsize-3">Event Chairman Photo</h6>
                    </label>
                    <br />
                    <img
                      :src="ChairmanData.MemberImage"
                      width="100"
                      v-if="ChairmanData.Fullname != ''"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6 class="mycard-text1 fsize-3">Event Chairman Details</h6>
                    </label>
                    <br />
                    <h5>{{ ChairmanData.Fullname }}</h5>
                    <h6>{{ ChairmanData.MemberDesignation }}</h6>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br /><br />
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">
                Event Director Details &nbsp;
                <v-tooltip right max-width="60%" allow-overflow>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon color="#F19A3E"> mdi-help-circle </v-icon>
                    </span>
                  </template>
                  <span>
                    Choose the event director by clicking the <v-icon color="blue">mdi-plus-circle</v-icon> icon below
                  </span>
                </v-tooltip>
              </v-card-title>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="12" lg="12" md="12">
                    <v-btn
                      icon
                      color="blue"
                      @click.prevent="addDirectorRecordPrompt = true"
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      color="red"
                      @click="deleteRow(4, 1, DirectorData)"
                    >
                      <v-icon>mdi-delete-circle-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6 class="mycard-text1 fsize-3">Event Director Photo</h6>
                    </label>
                    <br />
                    <img
                      :src="DirectorData.MemberImage"
                      width="100"
                      v-if="DirectorData.Fullname != ''"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6 class="mycard-text1 fsize-3">Event Director Details</h6>
                    </label>
                    <br />
                    <h5>{{ DirectorData.Fullname }}</h5>
                    <h6>{{ DirectorData.MemberDesignation }}</h6>
                  </v-col>
                </v-row>
              </v-container>
              <!-- <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6>
                        Membership Id of event chairman
                        <small> (Optional)</small>
                      </h6>
                    </label>
                    <v-text-field
                      v-model="ChairmanMembershipId"
                      :rules="ChairmanMembershipIdRules"
                      hint="Enter the membership ID of event chairman"
                      :loading="ChairmanMembershipIdLoadingFlag"
                      append-outer-icon="mdi-magnify-plus"
                      @click:append-outer="getMemberDetails(1)"
                      prepend-icon="mdi-account-box"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6>Event chairman details</h6>
                    </label>
                    <br />
                    <img
                      :src="ChairmanData.MemberImage"
                      width="100"
                      v-if="ChairmanData.Fullname != ''"
                    />
                    <h5>{{ ChairmanData.Fullname }}</h5>
                    <h6>{{ ChairmanData.MemberDesignation }}</h6>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6>
                        Select the project director
                        <small> (Optional)</small>
                      </h6>
                    </label>
                    <v-text-field
                      v-model="DirectorMembershipId"
                      :rules="DirectorMembershipIdRules"
                      hint="Membership id for event director"
                      append-outer-icon="mdi-magnify-plus"
                      :loading="DirectorMembershipIdLoadingFlag"
                      @click:append-outer="getMemberDetails(2)"
                      outlined
                      dense
                    ></v-text-field>
                    <v-autocomplete
                      @change="getDirectorMemberDetails"
                      :reduce="(option) => option.value"
                      :loading="MemberIdOptionsLoading"
                      :items="MemberIdOptions"
                      :rules="MemberIdRules"
                      v-model="MemberId"
                      prepend-icon="mdi-account-multiple-check"
                      append-outer-icon="mdi-refresh"
                      @click:append-outer="getMemberIdOptions"
                      required
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:selection="{ item }">
                        {{ item.text }}
                      </template>
                      <template v-slot:item="{ item }">
                        {{ item.text }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6>Event director details</h6>
                    </label>
                    <br />
                    <img
                      :src="DirectorData.MemberImage"
                      width="100"
                      v-if="DirectorData.Fullname != ''"
                    />
                    <h5>{{ DirectorData.Fullname }}</h5>
                    <h6>{{ DirectorData.MemberDesignation }}</h6>
                  </v-col>
                </v-row>
              </v-container> -->
            </v-card>
            <br /><br />
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">
                Describe about the Event &nbsp;
                <v-tooltip right max-width="60%" allow-overflow>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon color="#F19A3E"> mdi-help-circle </v-icon>
                    </span>
                  </template>
                  <span>
                    Write a brief description about the event. Should not exceed
                    500 characters
                  </span>
                </v-tooltip>
              </v-card-title>
              <p class="notes-text1">
                Should not exceed 500 characters
              </p>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="12" lg="12" md="12" id="step8">
                    <label>
                      <h6 class="mycard-text1 fsize-3">Event Description <small> (Optional)</small></h6>
                    </label>
                    <!-- <v-text-field
                      v-model="EventDescription"
                      :rules="EventDescriptionRules"
                      hint="Enter the event description"
                      outlined
                      dense
                    ></v-text-field> -->
                    <v-textarea
                      v-model="EventDescription"
                      value=""
                      :maxlength="500"
                      :counter="500"
                      outlined
                      solo
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br /><br />
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">
                Additional Event Images &nbsp;
                <v-tooltip right max-width="60%" allow-overflow>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon color="#F19A3E"> mdi-help-circle </v-icon>
                    </span>
                  </template>
                  <span>
                    Add event photographs by clicking the
                    <v-icon color="blue">mdi-plus-circle</v-icon> icon
                  </span>
                </v-tooltip>
              </v-card-title>
              <p class="notes-text1">
                Kindly note:
                <ol>
                  <li>Only JPEG, PNG file types are allowed to be uploaded.</li>
                  <li>Max file size per image should not exceed 1 MB.</li>
                </ol>
              </p>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="12" lg="12" md="12" id="step9">
                    <v-data-table
                      :headers="tableColumns2"
                      :items="AllDocuments"
                      hide-default-footer
                      disable-sort
                      id="document-table"
                    >
                      <template v-slot:no-data>
                        <v-btn
                          icon
                          color="blue"
                          @click.prevent="addDocumentRecordPrompt = true"
                        >
                          <v-icon>mdi-plus-circle</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:item.ImageUrl="{ item }">
                        <v-img
                          :src="item.ImageUrl"
                          width="100"
                          height="100"
                          v-if="item.ImageUrl != ''"
                        />
                      </template>
                      <template v-slot:item.action="{ item, index }">
                        <v-btn icon color="blue" @click.prevent="addEventPhoto">
                          <v-icon>mdi-plus-circle</v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          color="red"
                          @click="deleteRow(2, index, item)"
                        >
                          <v-icon>mdi-delete-circle-outline</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br /><br />
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">
                Guest Details &nbsp;
                <v-tooltip right max-width="60%" allow-overflow>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon color="#F19A3E"> mdi-help-circle </v-icon>
                    </span>
                  </template>
                  <span>
                    Add event dignitaries information by clicking the
                    <v-icon color="blue">mdi-plus-circle</v-icon> icon
                  </span>
                </v-tooltip>
              </v-card-title>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="12" lg="12" md="12" id="step10">
                    <v-data-table
                      :headers="tableColumns1"
                      :items="AllFaculty"
                      hide-default-footer
                      disable-sort
                      id="faculty-table"
                    >
                      <template v-slot:no-data>
                        <v-btn
                          icon
                          color="blue"
                          @click.prevent="addFacultyRecordPrompt = true"
                        >
                          <v-icon>mdi-plus-circle</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:item.ImageUrl="{ item }">
                        <v-img
                          :src="item.ImageUrl"
                          width="100"
                          height="100"
                          v-if="item.ImageUrl != ''"
                        />
                      </template>
                      <template v-slot:item.action="{ item, index }">
                        <v-btn
                          icon
                          color="blue"
                          @click.prevent="addFacultyRecordPrompt = true"
                        >
                          <v-icon>mdi-plus-circle</v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          color="red"
                          @click="deleteRow(1, index, item)"
                        >
                          <v-icon>mdi-delete-circle-outline</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br /><br />
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">
                PDF File Upload &nbsp;
                <v-tooltip right max-width="60%" allow-overflow>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon color="#F19A3E"> mdi-help-circle </v-icon>
                    </span>
                  </template>
                  <span> Upload relavant PDF document, if any </span>
                </v-tooltip>
              </v-card-title>
              <p class="notes-text1">
                Upload relavant PDF document, if any. <br/> Note: Only PDF format is
                permitted with size not exceeding 5 MB
              </p>
              <v-container>
                <v-row wrap>
                  <v-col align="center" cols="12" sm="6" lg="6" md="6" id="step11">
                    <v-btn
                      v-if="OldUploadedFile != ''"
                      @click="FilePreviewFlag = true"
                      color="primary"
                      elevation="30"
                      shaped
                      tile
                      large
                      class="
                        btn btn-primary
                        font-size-h6
                        px-15
                        py-4
                        my-3
                        mr-3
                        white--text
                      "
                    >
                      Old Uploaded file preview
                    </v-btn>
                    <v-icon v-if="OldUploadedFile != ''" color="red" @click="deleteOldFile(3)"> mdi-delete </v-icon>
                  </v-col>
                  <v-col cols="12" sm="6" lg="6" md="6" id="step11">
                    <h6 class="mycard-text1 fsize-3">Upload PDF <small> (Optional)</small></h6>
                    <v-file-input
                      :clearable="false"
                      truncate-length="25"
                      accept="application/pdf"
                      placeholder="Upload pdf"
                      v-model="UploadedFile"
                      prepend-icon="mdi-file-pdf"
                    >
                    <template v-slot:append-outer>
                      <v-icon color="red" @click="UploadedFile = {}"> mdi-delete </v-icon>
                    </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br /><br />
            <v-row wrap> </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <upload-progress
                  :ProgessStart="progessStart"
                  :UploadPercentage="uploadPercentage"
                ></upload-progress>
                <br />
                <v-btn
                  id="step12"
                  type="submit"
                  @click.prevent="confirmAlert"
                  :loading="SubmitFlag"
                  target="_blank"
                  color="#EDBE38"
                  class="font-size-h6 fsize-3 mr-3 my-3 white--text"
                >
                  <v-icon dark> mdi-briefcase-check </v-icon> &nbsp; Save
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import AddEventDocument from "@/view/pages/erp/events/AddEventDocument.vue";
import AddEventFaculty from "@/view/pages/erp/events/AddEventFaculty.vue";
import AddEventChairman from "@/view/pages/erp/events/AddEventChairman.vue";
import AddEventDirector from "@/view/pages/erp/events/AddEventDirector.vue";

export default {
  mixins: [common],
  components: {
    AddEventDocument,
    AddEventFaculty,
    AddEventChairman,
    AddEventDirector,
  },
  data() {
    return {
      EventCode: "",

      FilePreviewFlag: false,

      InvitationOldImage: "",
      EventPhotoOldImage: "",
      ChairmanOldImage: "",
      DirectorOldImage: "",

      OldInvitationImageFlag: false,
      OldEventPhotoImageFlag: false,
      OldUploadedFileFlag: false,

      TooltipShow1: false,

      TourOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: "Skip",
          buttonPrevious: "Previous",
          buttonNext: "Next",
          buttonStop: "Finish",
        },
      },
      TourSteps: [
        {
          target: "#step1",
          content:
            "Try it, you'll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.",
          params: {
            placement: "top",
          },
        },
        {
          target: "#step2",
          content:
            "Try it, you'll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.",
          params: {
            placement: "top",
          },
        },
        {
          target: "#step3",
          content:
            "Try it, you'll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.",
          params: {
            placement: "top",
          },
        },
      ],
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      StartupLoadingFlag: false,
      SubmitFlag: false,
      SearchFlag: false,
      SearchResultFlag: false,
      ShowFormFlag: false,
      NewTitleFlag: true,
      NotesFlag: false,
      rows: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      CheckoutTableHeaders: [],

      tab: null,
      TabDisable1: false,
      TabDisable2: false,
      TabDisable3: false,

      TabFlag3: false,

      TotalAmount: 0,
      Contribution: [],

      TotalAmount1: 0,
      TotalAmount2: 0,

      Member: {},
      Order: {},
      MemberFlag: false,

      PenaltySubscription: [],

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      progessStart: 0,
      uploadPercentage: 0,

      JciYearCode: "",

      DayTypeRules: [(v) => !!v || "Event day type is required"],
      DayType: "",
      DayTypeOptions: [
        { value: "", text: "- Select -" },
        { value: 1, text: "Single Day" },
        { value: 2, text: "Multiple Day" },
      ],
      DayTypeOptionsLoading: false,

      HostType: 1,
      HostTypeRules: [(v) => !!v || "Host type is required"],

      HostZoneCode: "",
      HostZoneCodeOptions: [],
      HostZoneCodeRules: [(v) => !!v || "Host Zone is required"],
      HostZoneCodeOptionsLoading: false,

      HostLomCode: "",
      HostLomCodeOptions: [],
      HostLomCodeRules: [(v) => !!v || "Host LO is required"],
      HostLomCodeOptionsLoading: false,

      EventTypeRules: [(v) => !!v || "Event type is required"],
      EventType: 3,
      EventTypeOptions: [
        { value: "", text: "- Select -" },
        { value: 1, text: "National Events" },
        { value: 2, text: "Zone Events" },
        { value: 3, text: "LOM Events" },
      ],
      EventTypeOptionsLoading: false,

      PortfolioIdRules: [(v) => !!v || "Portfolio is required"],
      PortfolioId: "",
      PortfolioIdOptions: [],
      PortfolioIdOptionsLoading: false,

      EfficiencyIdRules: [],
      EfficiencyId: "",
      EfficiencyIdOptions: [],
      EfficiencyIdOptionsLoading: false,

      EventCategoryIdRules: [(v) => !!v || "Event category is required"],
      EventCategoryId: "",
      EventCategoryIdOptions: [],
      EventCategoryIdOptionsLoading: false,

      EventSubTypeIdRules: [
        // (v) => !!v || "Event category is required"
      ],
      EventSubTypeId: "",
      EventSubTypeId1: "",
      EventSubTypeIdOptions: [],
      EventSubTypeIdOptionsLoading: false,

      ZoneName: "",

      ZoneCodeRules: [],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      LomName: "",

      LomCodeRules: [],
      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      EventName: "",
      EventNameRules: [(v) => !!v || "Event name is required"],

      FromDate: "",
      // FromDate: LuxonDateTime.local().plus({days: 0}).toISO(),
      FromDateRules: [(v) => !!v || "From date is required"],
      DateModal1: false,

      FromDateMin: "2022-01-01",
      FromDateMax: "2022-02-28",

      textFieldProps1: {
        prependIcon: "event",
        rules: [(v) => !!v || "From date is required"],
      },
      dateProps1: {
        headerColor: "#4285f4",
      },
      timeProps1: {
        useSeconds: false,
        ampmInTitle: true,
        scrollable: true,
        headerColor: "#4285f4",
        format: "ampm",
      },

      StartTime: null,
      TimeModal1: false,

      ToDate: "",
      ToDateRules: [(v) => !!v || "To date is required"],
      DateModal2: false,

      ToDateMin: "2022-01-01",
      ToDateMax: "2022-02-28",

      textFieldProps2: {
        prependIcon: "event",
        rules: [(v) => !!v || "To date is required"],
      },
      dateProps2: {
        headerColor: "#4285f4",
      },
      timeProps2: {
        useSeconds: false,
        ampmInTitle: true,
        scrollable: true,
        headerColor: "#4285f4",
        format: "ampm",
      },

      EndTime: null,
      TimeModal2: false,

      VenueDetails: "",
      VenueDetailsRules: [],

      ChairmanData: {},
      ChairmanMembershipId: "",
      ChairmanMembershipIdRules: [],
      ChairmanMembershipIdLoadingFlag: false,
      addChairmanRecordPrompt: false,

      DirectorData: {},
      DirectorMembershipId: "",
      DirectorMembershipIdRules: [],
      DirectorMembershipIdLoadingFlag: false,
      addDirectorRecordPrompt: false,

      EventDescription: "",
      EventDescriptionRules: [],

      AllFaculty: [],
      uploadedFacultyImages: [],
      tableColumns1: [
        { text: "Guest Type", value: "FacultyTypeName" },
        { text: "Member Type", value: "MemberTypeName" },
        { text: "Membership ID", value: "MembershipId" },
        { text: "Guest Name", value: "FacultyName" },
        { text: "Guest Details", value: "FacultyDetails" },
        { text: "Image", value: "ImageUrl" },
        { text: "Action", value: "action" },
      ],

      Faculty: {},
      addFacultyRecordPrompt: false,

      AllDocuments: [],
      uploadedEventImages: [],
      tableColumns2: [
        { text: "Document Type", value: "DocumentTypeName" },
        { text: "Description", value: "DocumentDescription" },
        { text: "Image", value: "ImageUrl" },
        { text: "Action", value: "action" },
      ],

      Document: {},
      addDocumentRecordPrompt: false,

      FacultyTypeIdRules: [(v) => !!v || "Faculty type is required"],
      FacultyTypeIdOptions: [],

      MemberTypeIdRules: [(v) => !!v || "Member type is required"],
      MemberTypeIdOptions: [
        { value: "", text: "- Select -" },
        { value: 1, text: "Member" },
        { value: 2, text: "Non Member" },
      ],

      MembershipIdRules: [],
      FacultyNameRules: [],
      FacultyDetailsRules: [],

      DocumentTypeIdRules: [(v) => !!v || "Document type is required"],
      DocumentTypeIdOptions: [],

      DocumentDescriptionRules: [],

      current_photo: null,

      MemberIdRules: [],
      MemberId: "",
      MemberIdOptions: [],
      MemberIdOptionsLoading: false,

      CurrentDesignation: "",
      MemeberFullname: "",

      image: {
        src: "",
        type: null,
      },

      InvitationImage: {},
      EventPhotoImage: {},
      EventPhotoImageLimit: 3,

      UploadedFile: {},
      OldUploadedFile: "",

      uploadedImages: [],
      TotalImages: 0,

      PageInfo: [],
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.JciYearCode = tr.CurrentJciYearId;
        var YearName = tr.CurrentJciYearName;
        this.YearName = YearName;

        var MonthCode = this.$route.query.month;
        MonthCode = MonthCode == (null || undefined) ? 0 : MonthCode;
        console.log({ MonthCode });
        this.MonthCode = MonthCode;

        var EventCode = this.$route.query.v2;
        EventCode = EventCode == (null || undefined) ? 0 : EventCode;
        console.log({ EventCode });
        this.EventCode = EventCode;

        var tempDate1 = YearName + "-" + MonthCode + "-01";
        console.log({ tempDate1 });

        var tempDate2 = new Date(tempDate1);
        console.log({ tempDate2 });
        var MonthName = tempDate2.toLocaleString("en-us", { month: "long" });
        console.log({ MonthName });
        this.MonthName = MonthName;

        var MrfStartDate = tr.MrfStartDate;
        console.log({ MrfStartDate });

        this.FromDateMin = MrfStartDate;
        this.ToDateMin = MrfStartDate;

        var MrfEndDate = tr.MrfEndDate;
        console.log({ MrfEndDate });

        this.FromDateMax = MrfEndDate;
        this.ToDateMax = MrfEndDate;

        this.pageData();
      }
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.LoadingFlag = false;
      this.ZoneCodeOptionsLoading = false;
    },
    ZoneCode: function () {
      console.log("watch ZoneCode");
      // this.getRegionCodeOptions();
      // this.LomCode = "";
      // this.getLomCodeOptions();
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LoadingFlag = false;
      this.LomCodeOptionsLoading = false;
    },
    PortfolioId: function () {
      console.log("watch PortfolioId");
      this.getEventCategoryIdOptions();
    },
    PortfolioIdOptions: function () {
      console.log("watch PortfolioIdOptions");
      this.LoadingFlag = false;
      this.PortfolioIdOptionsLoading = false;
    },
    EfficiencyIdOptions: function () {
      console.log("watch EfficiencyIdOptions");
      this.LoadingFlag = false;
      this.EfficiencyIdOptionsLoading = false;
    },
    EventCategoryId: function () {
      console.log("watch EventCategoryId");
      this.getEventSubTypeIdOptions();
    },
    EventCategoryIdOptions: function () {
      console.log("watch EventCategoryIdOptions");
      this.LoadingFlag = false;
      this.EventCategoryIdOptionsLoading = false;
    },
    EventSubTypeIdOptions: function () {
      console.log("watch EventSubTypeIdOptions");
      this.LoadingFlag = false;
      this.EventSubTypeIdOptionsLoading = false;
    },
    ChairmanData: function () {
      console.log("watch ChairmanData");
      // this.LoadingFlag = false;
      // this.ChairmanMembershipIdLoadingFlag = false;
    },
    MemberIdOptions: function () {
      console.log("watch MemberIdOptions");
      this.MemberIdOptionsLoading = false;
    },
    InvitationImage: function () {
      console.log("watch InvitationImage");
      var file = this.InvitationImage.UploadedImage;
      console.log({ file });
      const src = this.InvitationImage.ImageUrl;
      console.log({ src });
      const type = this.InvitationImage.ImageType;
      console.log({ type });
    },
    HostZoneCodeOptions: function () {
      console.log("watch HostZoneCodeOptions");
      this.HostZoneCodeOptionsLoading = false;
    },
    HostLomCodeOptions: function () {
      console.log("watch HostLomCodeOptions");
      this.HostLomCodeOptionsLoading = false;
    },
    HostZoneCode: function () {
      console.log("watch HostZoneCode");
      this.getHostLomCodeOptions();
    },
  },
  methods: {
    backPage() {
      this.$router.go(-1);
    },
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    startTour() {
      console.log("startTour called");
      var TourFlag = this.PageInfo.TourFlag;
      console.log({ TourFlag });
      if (TourFlag == 1) {
        var TourSteps = this.PageInfo.TourSteps;
        console.log({ TourSteps });
        // this.$tours["myTour"].start();
        introJs()
          .setOptions({
            steps: [
              {
                element: document.querySelector("#step1"),
                intro: "Select whether the event is one day or multi day event",
              },
              /*
              {
                element: document.querySelector("#step2"),
                intro: "Select event end date",
              },
              {
                element: document.querySelector("#step3"),
                intro: "Select event venue",
              },
              */
              {
                element: document.querySelector("#step4"),
                intro: "Select the portfolio suitable for each event",
              },
              {
                element: document.querySelector("#step5"),
                intro: "Select the category suitable for each event",
              },
              {
                element: document.querySelector("#step6"),
                intro: "Type the name of your event here",
              },
              {
                element: document.querySelector("#step7"),
                intro: "Choose the 100% efficiency, if your event is eligible",
              },
              {
                element: document.querySelector("#step8"),
                intro:
                  "Write a brief description about the event not exceeding 250 characters",
              },
              {
                element: document.querySelector("#step9"),
                intro:
                  "Upload event images in this section. JPEG and PNG file types are only permitted. Each file should not be more than 1 MB.",
              },
              {
                element: document.querySelector("#step10"),
                intro:
                  "Enter the dignitaries details who participated in the event.",
              },
              {
                element: document.querySelector("#step11"),
                intro:
                  "Upload the pdf document in this section. This is optional.",
              },
              {
                element: document.querySelector("#step12"),
                intro: "Click here to submit the event.",
              },
            ],
          })
          .start();
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    addEventPhoto() {
      var n2 = this.AllDocuments.length;
      console.log({ n2 });
      var m2 = this.EventPhotoImageLimit;
      console.log({ m2 });
      if (n2 < m2) {
        this.addDocumentRecordPrompt = true;
      } else {
        var message = "Event photos maximum limit " + m2 + " reached. ";
        this.sweetAlert("error", message, false);
      }
    },
    addImage(id) {
      console.log("addImage called");
      console.log({ id });
      switch (id) {
        case 1:
          var file = this.InvitationImage.UploadedImage;
          console.log({ file });
          var src = URL.createObjectURL(file);
          console.log({ src });
          if (this.InvitationImage.src) {
            URL.revokeObjectURL(this.InvitationImage.src);
          }
          var FileType = file.type;
          console.log({ FileType });
          this.InvitationImage = {
            DocumentFlag: true,
            DocumentTypeId: null,
            DocumentTypeName: null,
            DocumentDescription: null,
            ImageType: FileType,
            ImageUrl: src,
            UploadedImage: file,
          };
          break;

        case 2:
          var file = this.EventPhotoImage.UploadedImage;
          console.log({ file });
          var src = URL.createObjectURL(file);
          console.log({ src });
          if (this.EventPhotoImage.src) {
            URL.revokeObjectURL(this.EventPhotoImage.src);
          }
          var FileType = file.type;
          console.log({ FileType });
          this.EventPhotoImage = {
            DocumentFlag: true,
            DocumentTypeId: null,
            DocumentTypeName: null,
            DocumentDescription: null,
            ImageType: FileType,
            ImageUrl: src,
            UploadedImage: file,
          };
          break;

        case 3:
          var file = this.EventPhotoImage.UploadedImage;
          console.log({ file });
          var src = URL.createObjectURL(file);
          console.log({ src });
          if (this.EventPhotoImage.src) {
            URL.revokeObjectURL(this.EventPhotoImage.src);
          }
          var FileType = file.type;
          console.log({ FileType });
          this.EventPhotoImage = {
            DocumentFlag: true,
            DocumentTypeId: null,
            DocumentTypeName: null,
            DocumentDescription: null,
            ImageType: FileType,
            ImageUrl: src,
            UploadedImage: file,
          };
          break;

        default:
          break;
      }
    },
    deleteImage(id) {
      console.log("deleteImage called");
      console.log({ id });
      switch (id) {
        case 1:
          this.InvitationImage = {
            DocumentFlag: false,
            DocumentTypeId: null,
            DocumentTypeName: null,
            DocumentDescription: null,
            ImageType: null,
            ImageUrl: null,
            UploadedImage: null,
          };
          break;

        case 2:
          this.EventPhotoImage = {
            DocumentFlag: false,
            DocumentTypeId: null,
            DocumentTypeName: null,
            DocumentDescription: null,
            ImageType: null,
            ImageUrl: null,
            UploadedImage: null,
          };
          break;

        default:
          break;
      }
    },
    deleteOldFile(type) {
      console.log("deleteOldFile called");
      console.log("type=" + type);
      switch (type) {
        case 1:
          this.OldInvitationImageFlag = true;
          this.InvitationOldImage = "";
          break;
        case 2:
          this.OldEventPhotoImageFlag = true;
          this.EventPhotoOldImage = "";
          break;
        case 3:
          this.OldUploadedFileFlag = true;
          this.OldUploadedFile = "";
          break;

        default:
          console.log("This " + type + " type is not found.");
          break;
      }
    },
    loadImage(event, k) {
      console.log("loadImage is called");
      console.log({ k });
      var n1 = this.TotalImages;
      console.log({ n1 });
      /*
      const file = event.target.files[0];
      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          var result = event.target.result;
          const blob = URL.createObjectURL(file);
          console.log({ blob });
          this.uploadedImages[k] = {
            file: file,
            src: blob,
            type: null,
          };
          this.TotalImages = n1 + 1;
        };
        // reader.readAsDataURL(file);
      } else {
        console.log("Sorry, FileReader API not supported");
      }
      */

      const { files } = event.target;
      if (files && files[0]) {
        var file = event.target.files[0];
        const blob = URL.createObjectURL(files[0]);
        console.log({ blob });
        if (k == 1) {
          if (this.InvitationImage.src) {
            URL.revokeObjectURL(this.InvitationImage.src);
          }
          this.InvitationImage = {
            file: file,
            src: blob,
            type: null,
          };
        }
        /*
        var n1 = this.uploadedImages.length;
        console.log({ n1 });
        var images = this.uploadedImages;
        console.log({ images });
        var file = event.target.files[0];
        var src = n1 > k ? this.uploadedImages[k].src : null;
        this.TotalImages = n1 + 1;
        */
        // const reader = new FileReader();
        // reader.onload = (e) => {};
        // reader.readAsArrayBuffer(files[0]);
      }
    },
    addRecord() {
      console.log("addRecord called");
      var url = "/staff/list";
      var params = {};
      this.pageRedirect(params, url);
    },
    resetForm() {
      this.$refs.form1.reset();
      this.$refs.form2.reset();
      this.Member = {};
      this.MemberId = "";
      this.MembershipId = "";
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    resetMember() {
      console.log("resetMember called");
      this.LomCode = "";
      this.Member = {
        MemberId: "",
        PrimaryMobile: "",
        EmailId: "",
      };
      this.MemberFlag = false;
    },
    resetSubscription() {
      this.SearchResultFlag = false;
      this.rows = [];
      this.PenaltySubscription = [];
    },
    refreshPageData() {
      console.log("pageData called");
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "lom_events",
        Action: "edit",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
      this.OldInvitationImageFlag = false;
      this.OldEventPhotoImageFlag = false;
      this.OldUploadedFileFlag = false;
    },
    pageData() {
      console.log("refreshPageData called");

      var ZoneName = this.$session.get("ZoneName");
      ZoneName = ZoneName == (null || undefined) ? "" : ZoneName;
      console.log({ ZoneName });
      this.ZoneName = ZoneName;

      var ZoneCode = this.$session.get("ZoneId");
      ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
      console.log({ ZoneCode });
      this.ZoneCode = ZoneCode;

      var LomCode = this.$session.get("LomId");
      LomCode = LomCode == (null || undefined) ? "" : LomCode;
      console.log({ LomCode });
      this.LomCode = LomCode;

      var LomName = this.$session.get("LomName");
      LomName = LomName == (null || undefined) ? "" : LomName;
      console.log({ LomName });
      this.LomName = LomName;

      var CurrentDesignation = this.$session.get("CurrentDesignation");
      CurrentDesignation =
        CurrentDesignation == (null || undefined) ? "" : CurrentDesignation;
      console.log({ CurrentDesignation });
      this.CurrentDesignation = CurrentDesignation;

      var MemeberFullname = this.$session.get("MemeberFullname");
      MemeberFullname =
        MemeberFullname == (null || undefined) ? "" : MemeberFullname;
      console.log("MemeberFullname=" + MemeberFullname);
      this.MemeberFullname = MemeberFullname;

      this.getHostZoneCodeOptions();
      this.getZoneCodeOptions();
      this.getPortfolioIdOptions();
      this.getEfficiencyIdOptions();
      this.getTableRecords();
      // this.getMemberIdOptions();
      // this.getFacultyTypeIdOptions();
      // this.getDocumentTypeIdOptions();
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/jci-events/show";
      var upload = {
        UserInterface: 1,
        Year: this.JciYearCode,
        Month: this.MonthCode,
        Lom: this.LomCode,
        EventCode: this.EventCode,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = {};
      var table = {};
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.LoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          console.log({ records });

          if (flag == 1) {
            thisIns.JciYearCode = records.YearId;
            thisIns.DayType = records.DayType;
            thisIns.HostType = records.IsNhqHost;
            thisIns.HostZoneCode =
              thisIns.HostType == 1 ? "" : records.HostZoneId;
            thisIns.HostLomCode =
              thisIns.HostType == 1 ? "" : records.HostLomId;
            thisIns.PortfolioId = records.PortfolioId;
            thisIns.EfficiencyId = records.EfficiencyId;
            thisIns.EventCategoryId = records.EventTypeId;
            thisIns.EventSubTypeId = records.EventSubTypeId;
            thisIns.EventName = records.JciEventName;
            thisIns.EventDescription = records.JciEventDescription;
            thisIns.ChairmanMembershipId = records.ChairmanMembershipId;
            thisIns.ChairmanData.Fullname = records.ChairmanData.Fullname;
            thisIns.ChairmanData.MemberDesignation =
              records.ChairmanData.MemberDesignation;
            thisIns.DirectorMembershipId = records.DirectorMembershipId;
            thisIns.DirectorData.Fullname = records.DirectorData.Fullname;
            thisIns.DirectorData.MemberDesignation =
              records.DirectorData.MemberDesignation;
            thisIns.FromDate = records.DateFrom1;
            thisIns.StartTime = records.DateFrom2;
            thisIns.ToDate = records.DateTo1;
            thisIns.EndTime = records.DateTo2;
            thisIns.VenueDetails = records.VenueDetails;
            thisIns.AllFaculty = records.Faculties;
            thisIns.AllDocuments = records.Documents;
            thisIns.ChairmanData = records.ChairmanData;
            thisIns.DirectorData = records.DirectorData;

            thisIns.InvitationOldImage = records.InvitationImages[0];
            thisIns.EventPhotoOldImage = records.WebsiteImages[0];
            thisIns.DirectorOldImage =
              records.DirectorData.DirectorMemberPublicPath;
            thisIns.ChairmanOldImage =
              records.ChairmanData.ChairmanMemberPublicPath;
            thisIns.OldUploadedFile = records.ConsolidateFilePath;

            thisIns.toast("success", output);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.LoadingFlag = false;
        });
    },
    addFacultyImages(event, k) {
      console.log("addFacultyImages called");
      var n1 = this.uploadedFacultyImages.length;
      var file = event.target.files[0];
      console.log("k=" + k + ", event=" + JSON.stringify(file));
      this.uploadedFacultyImages[k] = file;
    },
    addEventImages(event, k) {
      console.log("addEventImages called");
      var n1 = this.uploadedEventImages.length;
      var file = event.target.files[0];
      console.log("k=" + k + ", event=" + JSON.stringify(file));
      this.uploadedEventImages[k] = file;
    },
    addNewFaculty(faculty) {
      console.log("addNewFaculty called");
      this.addFacultyRecordPrompt = false;
      console.log({ faculty });
      this.Faculty = faculty;
      this.addNewRow(1);
    },
    hideChairmanDialog(data) {
      console.log("hideChairmanDialog called");
      console.log({ data });
      var MembershipId = data.MembershipId;
      MembershipId = MembershipId == (null || undefined) ? "" : MembershipId;
      console.log({ MembershipId });
      this.ChairmanMembershipId = MembershipId;
      this.ChairmanData = data;
      this.addChairmanRecordPrompt = false;
    },
    hideDirectorDialog(data) {
      console.log("hideDirectorDialog called");
      console.log({ data });
      var MembershipId = data.MembershipId;
      MembershipId = MembershipId == (null || undefined) ? "" : MembershipId;
      console.log({ MembershipId });
      this.DirectorMembershipId = MembershipId;
      this.DirectorData = data;
      this.addDirectorRecordPrompt = false;
    },
    addNewDocument(document) {
      console.log("addNewDocument called");
      this.addDocumentRecordPrompt = false;
      console.log("document=" + JSON.stringify(document));
      this.Document = document;
      this.addNewRow(2);
    },
    addNewRow(tableId) {
      console.log("addNewRow called");

      console.log("tableId=" + tableId);

      switch (tableId) {
        case 1:
          var n1 = this.AllFaculty.length;
          console.log("n1=" + n1);
          var FacultyFlag = this.Faculty.FacultyFlag;
          console.log("FacultyFlag=" + FacultyFlag);
          if (FacultyFlag) {
            this.AllFaculty.push(this.Faculty);
          }
          this.Faculty = {};
          break;

        case 2:
          var n2 = this.AllDocuments.length;
          console.log("n2=" + n2);
          var DocumentFlag = this.Document.DocumentFlag;
          console.log("DocumentFlag=" + DocumentFlag);
          var m2 = this.EventPhotoImageLimit;
          console.log({ m2 });
          if (n2 < m2) {
            if (DocumentFlag) {
              this.AllDocuments.push(this.Document);
            }
          } else {
            var message = "Event photos maximum limit " + m2 + " reached. ";
            this.sweetAlert("error", message, false);
          }
          this.Document = {};
          break;

        default:
          break;
      }
    },
    deleteRow(tableId, index, tr) {
      console.log("deleteRow called");
      console.log({ tableId });
      console.log({ index });
      console.log({ tr });
      if (index >= 0) {
        switch (tableId) {
          case 1:
            this.AllFaculty.splice(index, 1);
            break;

          case 2:
            this.AllDocuments.splice(index, 1);
            break;

          case 3:
            this.ChairmanMembershipId = "";
            this.ChairmanData = {};
            break;

          case 4:
            this.DirectorMembershipId = "";
            this.DirectorData = {};
            break;

          default:
            break;
        }
      }
    },
    getPortfolioIdOptions() {
      console.log("getPortfolioIdOptions called");
      this.LoadingFlag = true;
      this.PortfolioIdOptionsLoading = true;
      var selectbox1_source = "PortfolioId";
      var selectbox1_destination = "PortfolioIdOptions";
      var selectbox1_url = "api/portfolio/options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getEfficiencyIdOptions() {
      console.log("getEfficiencyIdOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        this.LoadingFlag = true;
        this.EfficiencyIdOptionsLoading = true;
        var selectbox1_source = "EfficiencyId";
        var selectbox1_destination = "EfficiencyIdOptions";
        var selectbox1_url = "api/year-wise-efficiency/options";
        var selectbox1_conditions_array = {
          UserInterface: 3,
          Year: yearCode,
          Lom: this.LomCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getMemberIdOptions() {
      console.log("getMemberIdOptions called");
      this.MemberIdOptionsLoading = true;
      var selectbox1_source = "MemberId";
      var selectbox1_destination = "MemberIdOptions";
      var selectbox1_url = "api/members/options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
        LomCode: this.LomCode,
        MemberType: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getEventCategoryIdOptions() {
      console.log("getEventCategoryIdOptions called");
      var PortfolioId = this.PortfolioId;
      console.log("PortfolioId=" + PortfolioId);
      if (PortfolioId != "") {
        this.LoadingFlag = true;
        this.EventCategoryIdOptionsLoading = true;
        var selectbox1_source = "EventCategoryId";
        var selectbox1_destination = "EventCategoryIdOptions";
        var selectbox1_url = "api/jci-event-type/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          Portfolio: PortfolioId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (PortfolioId == "") {
          message += "Portfolio should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getEventSubTypeIdOptions() {
      console.log("getEventSubTypeIdOptions called");
      var EventCategoryId = this.EventCategoryId;
      console.log("EventCategoryId=" + EventCategoryId);
      if (EventCategoryId != "") {
        this.LoadingFlag = true;
        this.EventSubTypeIdOptionsLoading = true;
        var selectbox1_source = "EventSubTypeId";
        var selectbox1_destination = "EventSubTypeIdOptions";
        var selectbox1_url = "api/jci-event-sub-type/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          JciEventType: EventCategoryId,
          NotAvailable: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
        this.EventSubTypeId = this.EventSubTypeId1;
        console.log("EventSubTypeId=" + this.EventSubTypeId);
      } else {
        var message = "";
        if (EventCategoryId == "") {
          message += "Event type should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getHostZoneCodeOptions() {
      console.log("getHostZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        this.HostZoneCodeOptionsLoading = true;
        var selectbox1_source = "HostZoneCode";
        var selectbox1_destination = "HostZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getHostLomCodeOptions() {
      console.log("getHostLomCodeOptions called");
      var YearCode = this.JciYearCode;
      var HostZoneCode = this.HostZoneCode;
      // this.HostLomCode = "";
      console.log("YearCode=" + YearCode + ", HostZoneCode=" + HostZoneCode);
      if (YearCode != "" && HostZoneCode != "") {
        this.HostLomCodeOptionsLoading = true;
        var selectbox1_source = "HostLomCode";
        var selectbox1_destination = "HostLomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 3,
          YearCode: YearCode,
          ZoneCode: HostZoneCode,
          AllOption: 0,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (HostZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        this.LoadingFlag = true;
        this.ZoneCodeOptionsLoading = true;
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var YearCode = this.JciYearCode;
      var ZoneCode = this.ZoneCode;
      console.log("YearCode=" + YearCode + ", ZoneCode=" + ZoneCode);
      if (YearCode != "" && ZoneCode != "") {
        this.LoadingFlag = true;
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 3,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
          AllOption: 0,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getFacultyTypeIdOptions() {
      console.log("getFacultyTypeIdOptions called");
      this.LoadingFlag = true;
      // this.PortfolioIdOptionsLoading = true;
      var selectbox1_source = "FacultyTypeId";
      var selectbox1_destination = "FacultyTypeIdOptions";
      var selectbox1_url = "api/sub-category/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        CategoryMainId: 17,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getDocumentTypeIdOptions() {
      console.log("getDocumentTypeIdOptions called");
      this.LoadingFlag = true;
      // this.PortfolioIdOptionsLoading = true;
      var selectbox1_source = "DocumentTypeId";
      var selectbox1_destination = "DocumentTypeIdOptions";
      var selectbox1_url = "api/sub-category/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        CategoryMainId: 16,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getDirectorMemberDetails() {
      console.log("getDirectorMemberDetails is called");
      this.DirectorData = {};
      this.DirectorMembershipId = "";
      var temp_code = this.MemberId;
      console.log("temp_code=" + temp_code);
      var idx = this.MemberIdOptions.map((e) => e.value).indexOf(temp_code);
      console.log("idx=" + idx);
      if (idx >= 0) {
        var member = this.MemberIdOptions[idx];
        console.log({ member });
        this.DirectorMembershipId = member.membership_id;
        this.DirectorData = {
          Fullname: member.member_name,
          MemberImage: member.photo,
          MemberDesignation: member.designation,
        };
      }
    },
    getMemberDetails(id) {
      console.log("getMemberDetails is called");

      var MembershipId = "";

      switch (id) {
        case 1:
          MembershipId = this.ChairmanMembershipId;
          this.ChairmanData = {};
          this.ChairmanMembershipIdLoadingFlag = true;
          break;
        case 2:
          MembershipId = this.DirectorMembershipId;
          this.DirectorData = {};
          this.DirectorMembershipIdLoadingFlag = true;
          break;

        default:
          break;
      }
      console.log("MembershipId=" + MembershipId);

      if (MembershipId != "") {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/search";
        var upload = {
          UserInterface: 2,
          MembershipId: MembershipId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SubmitFlag = false;
            thisIns.StartupLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            switch (id) {
              case 1:
                thisIns.ChairmanMembershipIdLoadingFlag = false;
                thisIns.ChairmanData = records;
                break;
              case 2:
                thisIns.DirectorMembershipIdLoadingFlag = false;
                thisIns.DirectorData = records;
                break;

              default:
                break;
            }

            if (flag == 1) {
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.ChairmanMembershipIdLoadingFlag = false;
            thisIns.DirectorMembershipIdLoadingFlag = false;
          });
      } else {
        var message = "";
        this.ChairmanMembershipIdLoadingFlag = false;
        this.DirectorMembershipIdLoadingFlag = false;
        if (MembershipId == "") {
          message += "Membership id should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    confirmAlert() {
      console.log("confirmAlert called");
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        /*
        var MemberTypeId = this.MemberTypeId;
        console.log("MemberTypeId=" + MemberTypeId);

        // Get Title details

        var TitleId = this.TitleCode;
        var TitleIndex = this.TitleCodeOptions.map((e) => e.value).indexOf(
          TitleId
        );
        console.log("TitleId=" + TitleId + ", TitleIndex=" + TitleIndex);
        var TitleName = this.TitleCodeOptions[TitleIndex].text;
        console.log("TitleName=" + TitleName);
        */
        var htmlTxt = "";
        // htmlTxt += "<br/> Title <b> " + TitleName + "</b>";

        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      // validate1 = false;
      var JciEventId = this.EventCode;
      console.log("JciEventId=" + JciEventId);

      if (validate1) {
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jci-events/update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        var MemberTypeId1 = this.MemberTypeId;
        var JciMemberTypeId = this.JciMemberTypeId;
        var MemberTypeId = MemberTypeId1 == JciMemberTypeId ? 1 : MemberTypeId1;
        console.log(
          "MemberTypeId1=" +
            MemberTypeId1 +
            ", JciMemberTypeId=" +
            JciMemberTypeId +
            ", MemberTypeId=" +
            MemberTypeId
        );

        var EventData = {
          EventId: JciEventId,
          DayType: this.DayType,
          Year: this.JciYearCode,
          EventType: this.EventType,
          HostType: this.HostType ? 1 : 0,
          HostZone: this.HostType == 1 ? null : this.HostZoneCode,
          HostLO: this.HostType == 1 ? null : this.HostLomCode,
          Portfolio: this.PortfolioId,
          EfficiencyCriteria: this.EfficiencyId,
          EventCategory: this.EventCategoryId,
          EventSubType: this.EventSubTypeId,
          Zone: this.ZoneCode,
          Lom: this.LomCode,
          EventName: this.EventName,
          EventDescription: this.EventDescription,
          ChairmanMembershipId: this.ChairmanMembershipId,
          ChairmanData: this.ChairmanData,
          ChairmanName: this.ChairmanData.Fullname,
          ChairmanDetails: this.ChairmanData.MemberDesignation,
          DirectorMembershipId: this.DirectorMembershipId,
          DirectorName: this.DirectorData.Fullname,
          DirectorDetails: this.DirectorData.MemberDesignation,
          DirectorData: this.DirectorData,
          FromDate: this.FromDate,
          StartTime: this.StartTime,
          ToDate: this.ToDate,
          EndTime: this.EndTime,
          VenueDetails: this.VenueDetails,
          Faculty: this.AllFaculty,
          Documents: this.AllDocuments,
          OldInvitationImageFlag: this.OldInvitationImageFlag,
          OldEventPhotoImageFlag: this.OldEventPhotoImageFlag,
          OldUploadedFileFlag: this.OldUploadedFileFlag,
        };

        console.log({ EventData });

        console.table(this.ChairmanData);
        console.table(this.DirectorData);

        let upload = new FormData();
        upload.append("UserInterface", 1);
        upload.append("Form", JSON.stringify(EventData));

        var ChairmanImageName = this.ChairmanData.UploadedImageName;
        console.log({ ChairmanImageName });

        ChairmanImageName =
          ChairmanImageName == (null || undefined) ? "" : ChairmanImageName;

        if (ChairmanImageName != "") {
          upload.append(
            "ChairmanImage",
            this.ChairmanData.UploadedImage,
            ChairmanImageName
          );
        }

        var DirectorImageName = this.DirectorData.UploadedImageName;
        console.log({ DirectorImageName });

        DirectorImageName =
          DirectorImageName == (null || undefined) ? "" : DirectorImageName;

        if (DirectorImageName != "") {
          upload.append(
            "DirectorImage",
            this.DirectorData.UploadedImage,
            DirectorImageName
          );
        }

        upload.append("InvitationImage", this.InvitationImage.UploadedImage);
        upload.append(
          "InvitationDescription",
          this.InvitationImage.DocumentDescription
        );

        upload.append("EventPhotoImage", this.EventPhotoImage.UploadedImage);
        upload.append(
          "EventPhotoDescription",
          this.EventPhotoImage.DocumentDescription
        );
        upload.append("UploadedFile1", this.UploadedFile);

        var n1 = this.AllFaculty.length;
        var m1 = 0;
        for (var i = 0; i < n1; i++) {
          var uploadedImageFlag = this.AllFaculty[i].FacultyFlag;
          console.log(
            "i=" + i + ", m1=" + m1 + ", uploadedImageFlag=" + uploadedImageFlag
          );
          if (uploadedImageFlag) {
            var uploadedImageId = "FacultyImages" + m1;
            var uploadedImageFile = this.AllFaculty[i].UploadedImage;
            console.log(
              "i=" +
                i +
                ", uploadedImageId=" +
                uploadedImageId +
                ", file=" +
                JSON.stringify(uploadedImageFile)
            );
            // var uploadedFile = this.$refs[refName].files[0]
            upload.append(uploadedImageId, uploadedImageFile);
            m1++;
          }
        }

        var n2 = this.AllDocuments.length;
        var m2 = 0;
        for (var j = 0; j < n2; j++) {
          var uploadedImageFlag = this.AllDocuments[j].DocumentFlag;
          console.log(
            "j=" + j + ", m2=" + m2 + ", uploadedImageFlag=" + uploadedImageFlag
          );
          if (uploadedImageFlag) {
            var uploadedImageId = "EventImages" + m2;
            var uploadedImageFile = this.AllDocuments[j].UploadedImage;
            console.log(
              "j=" +
                j +
                ", uploadedImageId=" +
                uploadedImageId +
                ", file=" +
                JSON.stringify(uploadedImageFile)
            );
            // var uploadedFile = this.$refs[refName].files[0]
            upload.append(uploadedImageId, uploadedImageFile);
            m2++;
          }
        }
        /*
        var upload = {
          member: member,
        };
        */
        console.log("upload=" + JSON.stringify(upload));

        this.progessStart = 1;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              // thisIns.toast("success", output);
              thisIns.sweetAlert("success", output, true);
              thisIns.$router.push("/lom-events/month-list");
              // thisIns.ShowFormFlag = false;
              // thisIns.resetForm();
            } else {
              // thisIns.toast("error", output);
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        // this.toast("error", message);
        this.sweetAlert("error", output, false);
      }
    },
    hideFilePreviewFlag() {
      console.log("hideFilePreviewFlag called");
      this.FilePreviewFlag = false;
    },
  },
  beforeMount() {
    this.refreshPageData();
    /*
    this.tableColumns1.push(
      { text: "Description", value: "Description" },
      { text: "Subscription Fees", value: "AmountTxt" },
      { text: "Nos", value: "Qty" },
      { text: "Sub Total", value: "SubTotal" }
      // { text: "Actions", value: "actions" }
    );
    */
  },
  mounted: function () {
    // this.$tours["myTour"].start();
    // this.startTour();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */
#lom-events-create {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  #faculty-table {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
      font-size: 12px;
      color: red;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
      font-size: 15px;
    }
  }
  .mycard-head-title {
    // padding-bottom: 0;
    font-size: 20px;
    font-family: sans-serif;
    padding: 20px;
    text-align: center;
    word-break: break-word;
    line-height: normal;
    // text-transform: capitalize;
  }
  .mycard-title-1 {
    font-family: sans-serif;
    padding: 10px;
    text-align: left;
    word-break: break-word;
    line-height: normal;
    // text-transform: capitalize;
  }
  .mycard-title-2 {
    font-family: "Roboto", serif;
    padding: 10px;
    text-align: center;
    word-break: break-word;
    line-height: normal;
    // text-transform: capitalize;
  }
  .mycard-text1 {
    font-family: sans-serif;
    // text-transform: capitalize;
  }
  .mycard-text2 {
    font-size: 20px;
    font-family: "Merriweather", serif;
    color: red;
  }
  .notes-text1 {
    padding: 5px;
    margin-left: 2rem;
    font-size: 14px;
    font-family: sans-serif;
  }
  .mycard-text4 {
    font-size: 20px;
    font-family: "EB Garamond", serif;
    font-weight: bold;
    font-style: italic;
  }
  .mycard-text5 {
    font-size: 20px !important;
    font-family: "Roboto", serif;
    color: white;
  }
  .mycard-head-subtitle {
    padding-bottom: 0;
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-family: sans-serif;
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-family: sans-serif;
    font-size: 14px;
  }
  .animate-button1 {
    color: white;

    animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 10s; /* don't forget to set a duration! */
  }
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .note-text1 {
    margin-left: 2rem;
    font-size: 14px;
    padding: 5px;
  }
  .fsize-1 {
    font-size: 12px !important;
  }
  .fsize-2 {
    font-size: 14px !important;
  }
  .fsize-3 {
    font-size: 16px !important;
  }
  .fsize-4 {
    font-size: 18px !important;
  }
  .fsize-5 {
    font-size: 20px !important;
  }
  .fcolor-1 {
    color: #fff !important;
  }
  .bg-color-1 {
    background-color: #3a67b1;
  }
  .bg-color-2 {
    background-color: #0097d7;
  }
  .bg-color-3 {
    background-color: #edbe38;
  }
  .bg-color-4 {
    background-color: #56bda3;
  }
  .delete-icon {
    float: right;
    top: -50%;
  }
}
</style>